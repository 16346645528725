<template>
    <b-row>
                <b-col md="12">
    <b-card-actions action-collapse title="Group Email">  
        <validation-observer ref="simpleRules">
            <b-form>
            
                    <b-form-group>
                    <label>Group Name</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="title"
                    >
                          <b-form-input
                                v-model="input.title"
                                placeholder="Group Name"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                
                  

                
              
               
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
               
            </b-form>
        </validation-observer>
    </b-card-actions>
     </b-col>
     <b-col md="12" v-if="id > 0">
      <b-card-actions action-collapse title="Users Selected">  
                    <b-row>
                        <b-col cols="12">
                            <b-table
                                striped
                                hover
                                responsive
                           
                                :items="users_selected"
                                :fields="fields"
                                show-empty
                                empty-text="No matching records found"
                               
                               
                            >
                            
                             <template #cell(id)="data">
                                <feather-icon @click="remove_user(data.item.id)" icon="MinusIcon" />
                            </template>
                              <template #cell(name)="data">
                                    
                                {{ data.item.firstname }} {{ data.item.lastname }}
                            
                                </template>
                                
                            </b-table>
                            <b-pagination
                                v-if="totalRowsSelected > 0"
                                v-model="currentPageSelected"
                                :total-rows="totalRowsSelected"
                                :per-page="perPage"
                                align="center"
                                size="sm"
                                class="my-0"
                            />
                        </b-col>
                    </b-row>
                </b-card-actions>
     </b-col>
     <b-col md="12" v-if="id > 0">
         <b-card-actions action-collapse title="Search Users">  
                     <b-row>
                        <b-col md="12" class="mb-2">
                            <b-form-input
                                id="filter_user"
                                v-model="filter_user"
                                type="search"
                                placeholder="Type to Search"
                            />
                        </b-col>
                        <b-col cols="12">
                            <b-table
                                striped
                                hover
                                responsive
                             
                                :items="users"
                                :fields="fields"
                                show-empty
                                empty-text="No matching records found"
                                
                               
                               
                            >
                            <template #cell(id)="data">
                                <feather-icon @click="add_user(data.item)" icon="PlusIcon" />
                            </template>
                              <template #cell(name)="data">
                                    
                                {{ data.item.firstname }} {{ data.item.lastname }}
                            
                                </template>
                            </b-table>
                            

                          
                                <b-pagination
                                v-if="totalRows > 0"
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="center"
                                size="sm"
                                class="my-0"
                            />
                        </b-col>
                     </b-row>
                </b-card-actions>
     </b-col>
                
    </b-row>
    
      

 
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup, BTable,BPagination
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'


//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'

export default {
  components: {
    BCardActions,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormRating,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BPagination

  },
  directives: {
    Ripple,
  },
  data() {
    return {
        id:router.currentRoute.params.id,
        userData: JSON.parse(localStorage.getItem('userData')),
        filter_user:null,
        perPage: 5,
        users:[],
        users_selected:[],
        totalRows: 0,
        totalRowsSelected: 1,
        currentPage: 1,
        currentPageSelected: 1,
        fields: [
            {
                key: 'id', label: '',
            },
            {
                key: 'name', label: 'ชื่อ-นามสกุล',
            },
             {
                key: 'email', label: 'อีเมล',
            },
             {
                key: 'phone', label: 'เบอร์โทรศัพท์',
            },
       
      
        ],
        input:{
        
          name: null,
          uri:null,
          is_active:true,
         
        }
        
        
     
    }
  },
  created() {
     
     if(this.id != undefined){
        
        this.getData();
        this.get_users_selected();
      }
   
      
  },
  watch: {
    '$route.params.id' (to) {
       
        //this.id = newId;
        window.location.reload();
       /* this.id = newId
        if(newId != oldId){
            window.location.reload()
            // this.getDetail();
                //this.getPlace();

        }*/
        
        
            
    
    },
     filter_user(value){
           this.users = [];
          this.filter_user = value;
          this.currentPage = 1
          this.get_users();
      },
      
       currentPageSelected(value){
       
          this.users_selected = [];
        this.currentPageSelected = value*1;
         this.get_users_selected();
      },
      currentPage(value){
          this.users = [];
        this.currentPage = value;
         this.get_users();
      },
    
},
  methods: {
   
    async getData(){
     
      var groupmail = await axios.get('groupmail/v1/detail/'+this.id);
      
     if(groupmail){
         this.input = groupmail.data.data;
         

     }
     
    
      
    },
    async get_users(){
         
         const offset =  this.perPage*(this.currentPage-1);
         
         let data = {
            perpage: this.perPage,
            offset:offset,
            keyword:this.filter_user 
         }
         data = JSON.stringify(data);
         var users = await axios.post('/users/v1/lists', data);
         //console.log('filter',this.filter_user );
         if(users.data){
             this.users = users.data.data;
            console.log('users',this.users);
             this.totalRows = users.data.total;
         }else{
             this.totalRows = 0;
         }
         
        

      },
      async get_users_selected(){
         
         let offsets =  this.perPage*(this.currentPageSelected-1);
          
         let data = {
            id:this.id,
            limit: this.perPage,
            offset:offsets*1,
         }
         data = JSON.stringify(data);
         var users = await axios.post('/groupmail/v1/getusers', data);
        // console.log('users',users );
         if(users.data.data){

             this.users_selected = users.data.data;
            console.log('users',this.users_selected  );
             this.totalRowsSelected = users.data.total;
         }else{
             this.totalRowsSelected = 0;
         }
         
        

      },
      async add_user(item){
          let index =  this.users_selected.findIndex(u => u.id === item.id);
          if(index <  0){
            let data = {
              id : this.id,
              user_id:item.id
              
            }
             data = JSON.stringify(data);
            var users = await axios.post('/groupmail/v1/useradd', data);
            console.log('users',users);
            this.currentPageSelected=1;
            this.users_selected = [];
            this.get_users_selected();

            //this.users_selected.push(item);
            //this.totalRowsSelected = this.users_selected.length;
          }
          
        

      },
       async remove_user(id){
        var users = await axios.delete('/groupmail/v1/userdelete/'+this.id+'/'+id);
        this.currentPageSelected=1;
        this.users_selected = [];
        this.get_users_selected();
          
        //this.users_selected = this.users_selected.filter(v => v.id  != id);
        /* this.users_selected= $.grep(this.users_selected, function(e){ 
                return e.id != id; 
            });*/
          //this.totalRowsSelected = this.users_selected.length;
          
       

      },
    
    makeToast(variant = null,title,message) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
            console.log('input',this.input);
            this.input.created_by = this.userData.id;
            var data = JSON.stringify(this.input);
            if(this.id != undefined){
              axios.put('groupmail/v1/update/'+this.id,data)
              .then(res => { 
                console.log('res',res);
                if(!res.data.success){
                  this.makeToast('danger','Error 403',res.data.message);
                
                  
                }else{
                  this.$router.push('/group-email/list', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Done`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully updated as .`,
                        },
                      })
                  });
                  

                }
              // this.actions = res.data.data; 
              }).catch(error => {
                this.makeToast('danger','Error 403',res.data.message);
                  console.log('error',error);
              })

            }else{
               axios.post('groupmail/v1/add',data)
              .then(res => { 
                console.log('res',res);
                if(!res.data.success){
                  this.makeToast('danger','Error 403',res.data.message);
                
                  
                }else{
                  this.$router.push('/group-email/edit/'+res.data.data.id, () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Done`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully created.`,
                        },
                      })
                  });
                  

                }
            // this.actions = res.data.data; 
            }).catch(error => {
                this.makeToast('danger','Error 403',error);
                console.log('error',error);
            })

            }
          // eslint-disable-next-line
         
        }
      })
    },
    
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>